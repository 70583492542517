import type { WishlistsFavorites, WishlistsFavoritesItem } from '#root/api/clients/wishlists/data-contracts'

// this store is initialized on the favorites plugin
export const useFavoritesStore = (countryCode = useCountryCode()) => defineStore(`favorites_${countryCode}`, () => {
  const buyInStore = useBuyInStoreStore()
  const { $feature } = useNuxtApp()
  const { productsPerPage } = useAppConfig().components.favorites.list
  const { wishlists } = useApi()

  const error = ref(false)
  const favoriteId = ref('')
  const favorites = reactive<WishlistsFavoritesItem[]>([])
  const favoritesCount = ref(0)
  const pending = ref(false)

  const isFavorite = (id: string) => favorites.some((item) => item.id === id)

  const query = reactive({
    start: 0,
    count: productsPerPage
  })

  const load = async () => {
    if (!$feature.allowFavorites || buyInStore.employeeConnected)
      return { error: false }

    try {
      pending.value = true
      const data: WishlistsFavorites = await wishlists.$getFavorites({ query })
      favoritesCount.value = data.total
      favoriteId.value = data.favoriteId || ''
      if (favorites.length && query.start > 0)
        favorites.push(...(data.items || []))
      else
        favorites.splice(0, favorites.length, ...(data.items || []))
      return { error: false }
    }
    catch (e) {
      console.warn('Error loading favorites.', e)
      error.value = true
    }
    finally {
      pending.value = false
    }
  }

  const setFavoriteId = async (value) => {
    favoriteId.value = value || ''
    if (value)
      await load()
    else
      favorites.length = 0
  }

  const setFavoritesCount = (count) => {
    favoritesCount.value = count
  }

  const add = async (id: string, props?: Record<string, any>) => {
    if (!$feature.allowFavorites || buyInStore.employeeConnected)
      return { error: false }

    pending.value = true
    try {
      const data = await wishlists.$wishlistsAddToFavorites({
        id,
        qty: 1,
        itemType: 'product',
        favoriteId: favoriteId.value,
        ...props // some things won't get populated on the backend
      })
      query.start = 0
      await setFavoriteId(data.favoriteId)
      return { error: false }
    }
    catch (e) {
      console.warn(e)
      return { error: true }
    }
    finally {
      pending.value = false
    }
  }

  const remove = async (id: string) => {
    if (!$feature.allowFavorites || buyInStore.employeeConnected)
      return { error: false }

    pending.value = true

    try {
      const itemIndex = favorites.findIndex((i) => i.id === id)
      if (itemIndex === -1) throw (new Error('Item not found'))

      const itemId = favorites[itemIndex].itemId
      await wishlists.$removeFavoritesItem(favoriteId.value, itemId)

      favorites.splice(itemIndex, 1)
      favoritesCount.value--

      return { error: false }
    }
    catch (e) {
      console.warn(e)
      return { error: true }
    }
    finally {
      pending.value = false
    }
  }

  return {
    add,
    error,
    favoriteId,
    favorites,
    favoritesCount,
    isFavorite,
    load,
    pending,
    query,
    remove,
    setFavoriteId,
    setFavoritesCount
  }
}, {
  persist: {
    storage: persistedState.localStorage,
    paths: ['favoriteId']
  }
})()
