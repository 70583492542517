<template>
  <client-only>
    <div v-if="products" class="bg-brand-6 pt-4 bg-grey-90">
      <p class="mb-4 ml-4 text-xl fw-bold !mb-0 !text-base !fw-light">
        {{ content.title }}
      </p>
      <product-upsells
        v-model="selectedUpsells"
        :is-carousel="products.length > 1 && content.variant === 'carousel'"
        :show-button="content.addToCartControls === 'cta'"
        :show-checkbox="content.addToCartControls === 'checkbox'"
        :show-modal="$feature.showUpsellModal"
        :skip-check-inventory="siteId.includes('showcase')"
        :upsells="products"
        @open-quickshop="openQuickshop"
        @rec-click="sendClickImpression($event)"
      />
      <dialog-quickshop class-content="<lg:pb-0" position="bottom" :size="$viewport.lg ? 'lg' : undefined" />
    </div>
    <template #fallback>
      <div class="mt-10 h-34 w-82 skeleton" />
    </template>
  </client-only>
</template>

<script lang="ts" setup>
import type { ProductUpsell } from '#types/components/cms/product-upsell'
import type { Product } from '#root/api/clients/product/data-contracts'

const props = defineProps<{ content: ProductUpsell }>()

const { dialogOptions } = useAppConfig().components.product.upsell
const { selectedUpsells } = storeToRefs(useCartStore())
const { $gtm, $viewport } = useNuxtApp()
const { extractProduct, sendRecImpressionsEvent } = useMonetate()
const { siteId } = useCms()

const DialogQuickshop = createDialog('quickshop', { ...dialogOptions, teleport: () => !$viewport.lg })

const products = computed(() => {
  return props.content.recommendedProducts?.slice(0, props.content?.stackedCount || 3)?.map(
    (product) => {
      const extractedProduct = extractProduct(product)
      return {
        ...extractedProduct,
        image: extractedProduct.image.src,
        name: extractedProduct.title
      }
    }
  )
})

const openQuickshop = (product: Product) => DialogQuickshop.open({
  product,
  gtmConfig: { appSource: 'up-sell-complete-the-look', category: 'UpSellCompleteTheLook', list: 'UpSellCompleteTheLook' }
})

const sendClickImpression = (recId) => {
  sendRecImpressionsEvent([products.value?.find((prod) => prod.id === recId)], 1)
}

onMounted(() => {
  if (props.content.monetateDecision?.name !== 'Control')
    sendRecImpressionsEvent(props.content.recommendedProducts, props.content?.stackedCount || 3)
  if (products.value.length)
    $gtm.push('upsell.onUpsellImpression', products.value)
})
</script>
