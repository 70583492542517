<template>
  <div class="mb-8 md:mb-12">
    <cms-shared-media v-if="media" :media />
  </div>

  <div class="mb-4 container lg:mb-8 space-y-4">
    <h1 class="display-3">
      {{ title }}
    </h1>
    <p class="subtitle-2">
      {{ subtitle }}
    </p>
    <p v-if="!hidePageDate" class="text-md fw-medium">
      {{ date }}
    </p>
  </div>

  <slot />

  <div v-if="relatedArticles.length" class="mb-10 container">
    <h2 class="mb-8 text-center title-2">
      {{ $t.relatedContent }}
    </h2>
    <base-grid :cols="{ sm: 2, md: 4 }" gap="sm">
      <vf-article-card v-for="article in relatedArticles" :key="article.id" v-bind="article" />
    </base-grid>
  </div>
</template>

<script setup lang="ts">
import type { PageContent } from '#types/page'

const { page } = defineProps<{ page: PageContent }>()

const { getSearch, getMediaUrl } = useCms()

const { media, title, subtitle, date, hidePageDate, relatedQuery } = page

useSchemaOrg([
  defineArticle({
    headline: title,
    description: subtitle,
    datePublished: date
  })
])

const RELATED_ARTICLES_COUNT = '4'

let relatedArticles: any[] = []

if (relatedQuery) {
  const query = new URLSearchParams(relatedQuery)
  query.set('limit', RELATED_ARTICLES_COUNT)

  const data = await getSearch('article', { ...Object.fromEntries(query), limit: 4 })

  relatedArticles = (data?.result || []).map((article) => ({
    ...article,
    to: article.url,
    image: {
      src: getMediaUrl(article.picture?.url),
      alt: article.picture?.alt || article.title
    },
    tags: [article.tags[0]?.label]
  }))
}
</script>
