<template>
  <div
    ref="elementRef"
    class="relative"
    :class="{ '': !responsiveMedia.video && mediaLink && isOverlay }"
    @click="sectionName?.includes('opened-search-view')
      ? $gtm.push('topNav.onClickSearchContent', title || content.targets?.[0]?.text)
      : handlePromotionClick"
  >
    <div
      class="relative"
      :class="{ '': !responsiveMedia.video && mediaLink && isOverlay }"
    >
      <base-picture
        v-if="badge"
        :alt="badge.alt"
        class="absolute right-0 top-0 m-4 h-14 w-14"
        fit="contain"
        :src="badge.url"
      />
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        class="w-full"
        fit="cover"
        :lazy
      />
      <vf-video
        v-else-if="responsiveMedia.video"
        v-bind="responsiveMedia.video"
        ref="videoRef"
        :autoplay="!lazy"
        class="w-full"
        :controls
        fit="cover"
        loop
        muted
      />
      <div v-style="gradient" class="absolute bottom-0 w-full" />
      <base-link
        v-if="mediaLink"
        aria-hidden="true"
        class="absolute-0"
        tabindex="-1"
        :target="mediaLink.targetAttribute"
        :to="mediaLink.url"
      />
    </div>
    <div
      v-style:bg="backgrounds"
      :class="[contentLayoutClasses, contentAlignmentClasses, brandClasses.container]"
    >
      <div v-if="eyebrowText" v-style:c="eyebrowTextColor || subtitleColor" class="uppercase">
        {{ eyebrowText }}
      </div>

      <p v-if="title" v-style:c="titleColor" :class="titleStyle" data-test-id="cms-linked-card-title">
        <base-link v-if="mediaLink" :target="mediaLink.targetAttribute" :to="mediaLink.url">
          {{ title }}
        </base-link>
        <template v-else>
          {{ title }}
        </template>
      </p>
      <p
        v-if="subtitle"
        v-style:c="subtitleColor"
        :class="subtitleStyle"
        data-test-id="cms-linked-card-text"
      >
        {{ subtitle }}
      </p>
      <cms-rich-text
        v-if="richText"
        v-style:c="richTextColor || subtitleColor"
        class="pointer-within"
        :content="{ richText }"
      />
      <div
        v-if="linkType !== 'No-CTA' && targets.length"
        class="f-col gap-4 md:flex-row md:wrap lg:gap-6 not-first:pt-4 "
        :class="[ctaAlignmentClasses, equalTargetSizeClass, brandClasses.ctaContainer]"
        :style="{ '--cols': cols }"
      >
        <cms-shared-button
          v-for="(target, i) in targets"
          :key="i"
          v-bind="{ ...target, ...getTextLinkStyles(content) }"
          :size="sharedButton.size"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey, type SectionProvider } from '#content/components/cms/section/context'
import type { LinkedCardAlignment, LinkedCardContent, LinkedCardLayout } from '#types/components/cms/linked-card'
import type { Responsive } from '#types/common'
import type { Target } from '#types/target'

const { content, isPartOfCollection } = withDefaults(defineProps<{
  content: LinkedCardContent
  controls?: boolean
  isPartOfCollection?: boolean
  sectionName?: string
}>(), { controls: true })

const {
  backgroundColor,
  badge,
  equalTargetSize,
  eyebrowText,
  eyebrowTextColor,
  gradientBreakpoints = {},
  gradientStyle,
  gradientTone,
  linkType,
  media,
  mediaTarget,
  name,
  promotionTracking,
  responsiveLayout,
  richText,
  richTextColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [] as Target[],
  title,
  titleColor,
  titleStyle,
} = content

const { linkedCard: { brandClasses }, sharedButton } = useAppConfig().components.cms
const { getMedia } = useCms()
const { $viewport } = useNuxtApp()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const { lazy } = inject(SectionContextKey, {} as SectionProvider)
const videoRef = ref()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)

const contentLayout: Partial<Record<LinkedCardLayout, string>> = {
  below: '',
  overlay: 'absolute bottom-0 left-0 mb-4',
}

const contentAlignment: Partial<Record<LinkedCardAlignment, string>> = {
  left: '',
  center: 'items-center text-center',
}

const ctaAlignmentSmall: Record<LinkedCardAlignment, string> = {
  left: 'items-start',
  center: 'items-center'
}

const ctaAlignmentMedium: Record<LinkedCardAlignment, string> = {
  left: 'items-start',
  center: 'justify-center'
}

const contentLayoutClasses = [
  responsiveLayout.sm?.layout && `<md:${contentLayout[responsiveLayout.sm.layout]}`,
  responsiveLayout.md?.layout && `md:${contentLayout[responsiveLayout.md.layout]}`,
].filter(Boolean).join(' ')

const contentAlignmentClasses = [
  responsiveLayout.sm?.alignment && `<md:${contentAlignment[responsiveLayout.sm.alignment]}`,
  responsiveLayout.md?.alignment && `md:${contentAlignment[responsiveLayout.md.alignment]}`,
].filter(Boolean).join(' ')

const ctaAlignmentClasses = [
  responsiveLayout.sm?.alignment && `<md:${ctaAlignmentSmall[responsiveLayout.sm.alignment]}`,
  responsiveLayout.md?.alignment && `md:${ctaAlignmentMedium[responsiveLayout.md.alignment]}`,
].filter(Boolean).join(' ')

const gradientStyles = { full: 35, focused: 50, off: 0 }

const gradient = {
  display: Object.entries(gradientBreakpoints).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value ? 'block' : 'none'
  }), {} as Responsive),
  bgv: `linear-gradient(to bottom, transparent, ${gradientTone || 'transparent'})`,
  h: `${gradientStyles[gradientStyle || 'off']}%`,
}

const equalTargetSizeClass = [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:cols-$cols <md:cols-1 <md:auto-rows-fr',
  getValueForBreakpoint('md', equalTargetSize) && '~md:grid ~md:cols-$cols ~md:auto-rows-fr ~md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:cols-$cols lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

const cols = isPartOfCollection
  ? 'repeat(1,minmax(0,1fr))'
  : targets.map((target) => target.style?.sm === 'text-link' ? 'max-content' : 'minmax(0,1fr)').join(' ')

const isOverlay = responsiveLayout[$viewport.breakpoint]?.layout === 'overlay'

const backgrounds = Object.keys(backgroundColor || {}).reduce((acc, cur) => {
  const layout = getValueForBreakpoint(cur as keyof Responsive, responsiveLayout)?.layout

  return { ...acc, [cur]: layout?.includes('overlay') ? 'transparent' : backgroundColor?.[cur] }
}, {} as Record<keyof Responsive, string>)

onMounted(() => videoRef.value?.play().catch((e) => log.error(e)))
</script>
