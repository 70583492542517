<template>
  <div
    ref="promoBarRef"
    class="relative w-full"
    :class="{ hidden }"
    :style="{
      'background': colors[activeColorId]?.background,
      '--c': colors[activeColorId]?.textColor,
      '--ch': colors[activeColorId]?.hoverLinkColor || colors[activeColorId]?.textColor,
    }"
  >
    <base-carousel
      ref="scroller"
      autoplay
      class="group mx-a text-xs fw-medium lh-4 duration"
      data-test-id="promo-bar"
      :interval="autorotateTime"
      style="max-width: 548px"
    >
      <template v-if="displayedMessages.length > 1" #prev="{ action }">
        <base-button :aria-label="$t.previous" class="absolute inset-y-0 left-0 pb-4 c-$c <md:hidden hover:c-$ch" @click.stop="action">
          <vf-icon dir="left" name="chevron" size="md" />
        </base-button>
      </template>
      <template v-if="displayedMessages.length > 1" #next="{ action }">
        <base-button :aria-label="$t.next" class="absolute inset-y-0 right-0 pb-4 c-$c <md:hidden hover:c-$ch" @click.stop="action">
          <vf-icon dir="right" name="chevron" size="md" />
        </base-button>
      </template>
      <cms-section
        v-show="displayedMessages.includes(message) "
        v-for="(message, i) in messages"
        :key="i"
        class="min-h-10 w-full"
        :class="{ 'pb-4': displayedMessages.length > 1 }"
        :section="{ items: [{ ...message, isFullWidth: true }] as Segmentation[], name: 'promo-bar-message' }"
      />
      <template #pagination="{ activeItem }">
        <div v-if="displayedMessages.length > 1" class="absolute inset-x-0 bottom-0 flex justify-center py-2 space-x-2">
          <div
            v-for="(_, i) in displayedMessages"
            :key="i"
            class="h-2 w-2 cursor-pointer rounded-full"
            :class="{
              'bg-white': activeItem === i && colors[activeColorId]?.isLowContrast,
              'bg-black': activeItem === i && !colors[activeColorId]?.isLowContrast,
              'bg-grey-20': activeItem !== i,
            }"
          />
        </div>
      </template>
    </base-carousel>
    <base-button
      v-if="closable"
      :aria-label="$t.hide"
      class="absolute right-0 top-0 self-center p-3 c-$c duration hover:c-$ch"
      @click="hidden = 'true'"
    >
      <vf-icon name="close" />
    </base-button>
  </div>
</template>

<script lang="ts" setup>
import type { BaseCarousel as Carousel } from '#components'
import type { PromoBarColors, PromoBarContent } from '#types/components/cms/promo-bar'
import type { Segmentation } from '#types/components/cms/segmentation'

const { content } = defineProps<{
  content: PromoBarContent
}>()

const emptySections = useState<Record<string, boolean>>('emptySections', () => ({}))
const { maxPromoMessages } = useAppConfig().components.cms.promoBar
const header = useHeaderStore()
const promoBarRef = toRef(header.refs, 'promoBar')

const { autorotateTime, closable, messages = [] } = content

const hidden = useCookie<'true' | null>('promoBarClosed')

const scroller = ref<InstanceType<typeof Carousel>>()
const displayedMessages = computed(
  () => messages.filter((message) => !emptySections.value[message.id]).slice(0, maxPromoMessages)
)

const activeIndex = computed(() => scroller.value?.scroll?.activePage || 0)

const colors = useState<Record<string, PromoBarColors>>('promoBarColors', () => ({}))
const activeColorId = computed(() => (displayedMessages.value?.[activeIndex.value].id))
</script>
