<template>
  <div v-show="recommendedProducts?.length" :class="brandClasses.container">
    <h2 v-if="content?.title" class="mb-4 lg:mx-9 title-2 lg:mb-6">
      {{ content.title }}
    </h2>
    <client-only>
      <vf-carousel
        v-if="recommendedProducts?.length"
        class="-mx-2"
        :class="brandClasses.carouselContainer"
        :class-controls="['-mt-9 mx-2', brandClasses.carouselControls]"
        loop
      >
        <div
          v-for="({ ...card }, i) in recommendedProducts"
          :key="i"
          :class="[
            content.variant === '3-cards' ? 'w-full md:w-1/2 lg:w-1/3' : 'w-1/2 md:w-1/4 lg:w-1/6',
            brandClasses.productContainer,
          ]"
          @click="handleProductClick(card)"
        >
          <product-card-recommended :currency="currency[locale]" v-bind="card" :gtm-config="{ list: _list, category: _list }" :quickshop />
        </div>
      </vf-carousel>
      <template #fallback>
        <div
          class="grid children:skeleton gap-4 lg:px-9"
          :class="content.variant === '3-cards' ? 'md:cols-2 lg:cols-3' : 'cols-2 md:cols-4 lg:cols-6'"
        >
          <template v-for="i in content.variant === '3-cards' ? 1 : 2" :key="i">
            <div class="aspect-square" />
            <div class="aspect-square <md:hidden" />
            <div class="aspect-square <lg:hidden" />
          </template>
        </div>
      </template>
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import type { BloomreachRecommendations } from '#types/components/cms/bloomreach-recommendations'
import { extractBloomReachProduct } from '#core/utils/p13n'

const props = defineProps<{
  content: BloomreachRecommendations
}>()

const { brandClasses } = useAppConfig().components.cms.productRecommendations
const { currency } = useRuntimeConfig().public
const locale = useLocale()
const route = useRoute()
const { enableQuickShop } = useAppConfig().components.cms.productRecommendations
const { handleBloomreachProductClick } = useMonetate()

const quickshop = props.content.quickShopVisibility ? props.content.quickShopVisibility === 'visible' : enableQuickShop

const getRecommendedProducts = (products = []) =>
  products.map((product) => extractBloomReachProduct(product, props.content.showRatings))

const recommendedProducts = ref<any>([])

const loadProducts = () => {
  const products = window.vfbr?.[props.content.bloomreachIndex || 0] || []
  recommendedProducts.value = getRecommendedProducts(products)
}

const _list = getRecommendationListDescription({ list: 'Product Recommendation Carousel', title: props.content.title })

const handleProductClick = (card) => {
  handleBloomreachProductClick(card, props.content.title, route.path)
}

onMounted(() => {
  loadProducts()
  window.addEventListener('recalculateBloomreachVfa', loadProducts)
})

onBeforeUnmount(() => {
  window.removeEventListener('recalculateBloomreachVfa', loadProducts)
})
</script>
