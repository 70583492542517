<template>
  <div
    v-style:bg="backgroundColor"
    class="px-4 py-10 md:flex lg:px-10 "
    data-test-id="collection-vertical-list"
  >
    <div class="flex items-center md:w-1/2">
      <div class="space-y-4">
        <h2 v-if="title" v-style:c="titleColor" :class="titleStyle">
          {{ title }}
        </h2>
        <p v-if="subtitle" v-style:c="subtitleColor" :class="subtitleStyle">
          {{ subtitle }}
        </p>
        <ul class="space-y-4">
          <li v-for="(item, i) in items" :key="i" class="flex">
            <vf-link
              v-style:c="textLinkColor"
              class="hover:c-$ch "
              :class="textLinkStyle"
              :style="{ '--ch': textLinkHoverColor || textLinkColor }"
              :to="item.mediaTarget.url"
              :variant="textLinkUnderline === 'never' ? 'quieter' : textLinkUnderline === 'hover' ? 'quiet' : undefined"
              @focus="activeIndex = i"
              @mouseover="activeIndex = i"
            >
              <span
                v-if="bgv"
                v-style:bgv="bgv"
                class="duration <md:hidden hover:!bg-left"
                :style="{
                  'background-size': '200% 100%',
                  'background-position': activeIndex === i ? '0' : '-100%',
                  'background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent',
                }"
              >
                {{ item.title }}
              </span>
              <span :class="{ 'md:hidden': bgv }">
                {{ item.title }}
              </span>
            </vf-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="w-1/2 flex justify-center <md:hidden">
      <base-link
        aria-hidden="true"
        class="relative i-block"
        tabindex="-1"
        :to="items[activeIndex].mediaTarget.url"
      >
        <base-picture v-bind="getMedia(items[0].media).images" class="op-0" />
        <transition class="duration-500" name="fade">
          <base-picture
            v-bind="getMedia(items[activeIndex].media).images"
            :key="activeIndex"
            class="absolute-0 overflow-hidden rounded-lg"
          />
        </transition>
      </base-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import componentTypeConfig from './config'
import type { CollectionContent } from '#types/components/cms/collection'

const { content } = defineProps<{ content: CollectionContent<any> }>()

const {
  backgroundColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  textLinkColor,
  textLinkHoverColor,
  textLinkStyle,
  textLinkUnderline,
  title,
  titleColor,
  titleStyle
} = content

const { getMedia } = useCms()
const { itemsProp } = componentTypeConfig[content.type!] || {}

const items = (itemsProp ? content[itemsProp] : content.items) || []

const activeIndex = ref(0)

const bgv = textLinkColor && textLinkHoverColor && Object.values(textLinkColor)?.some((v) => v !== textLinkHoverColor)
  ? Object.keys(textLinkColor).reduce((acc, bp) => ({
    ...acc,
    [bp]: textLinkColor[bp] !== textLinkHoverColor
      ? `linear-gradient(to right, ${textLinkHoverColor}, ${textLinkHoverColor} 50%, ${textLinkColor[bp]} 50%)`
      : textLinkColor[bp]
  }), {})
  : undefined
</script>
