import type { ProfileInfoPayload } from '~/api/clients/consumer/data-contracts'

// TODO better type support for Profile Payloads GLOBAL15-106267. ProfileInfoData['consumerProfile']['consumerDetails'] can't be used because it's optional
type NewsletterPayload = {
  optIn?: boolean
  loyaltyEnroll?: boolean
  loyaltyOptIn?: boolean
  firstName?: string
  lastName?: string
  email?: string
  birthDate?: string
  interests?: string
  gender?: 'M' | 'F' | 'NA'
  acquisitionType?: string
  campaignName?: string
}

const shouldUnsubscribe = (isGuest, smsNumber, optIn) => !isGuest && smsNumber && optIn

const loadProfile = async (store, isGuest) => {
  if (import.meta.client && !store.profile && !isGuest) await store.get()
}

const updateProfile = async (store, isGuest) => {
  if (import.meta.client && !isGuest) await store.get()
}

type SubscriptionPayload = Exclude<Exclude<ProfileInfoPayload['consumerProfile'], undefined>['subscriptions'], undefined>[number]

const getProfilePayload = (subscriptions: SubscriptionPayload[], consumerDetails = {}): ProfileInfoPayload => ({
  consumerProfile: {
    consumerDetails,
    subscriptions,
  }
})

export default () => {
  const { interestOnlyForSubscription } = useAppConfig().pages.profile
  const { consumer } = useApi()
  const auth = useAuthStore()
  const isGuest = computed(() => !auth.loggedIn)

  const store = useUserStore()
  loadProfile(store, isGuest.value) // conditionally loads the profile if it's not already loaded

  const smsNumber = computed(() => store.subscriptions.sms?.recipient)
  const smsSubscription = computed(() => store.subscriptions.sms)
  const newsletterSubscribed = computed(() => !!store.profile?.subscriptions?.find(({ type }) => type === 'Newsletter')?.optin)
  const loyaltyNewsletterSubscribed = computed(() => !!store.profile?.subscriptions?.find(({ type }) => type === 'LoyaltyNewsletter')?.optin)

  const consumerId = ref('')

  async function sms(phone: string, phoneCode: string, optIn = true) {
    const serverDate = utcIsoDate(await getServerDate())

    const subscriptionPayload: any = {
      type: 'Newsletter',
      optin: optIn,
      doubleOptin: false,
      channel: 'sms',
      // the user can change the number in the form if not replied 'Y' to the SMS,
      // so we need to ensure we unsubscribe the previous subscribed number, not the field input
      recipient: !optIn ? smsNumber.value : formatE164phone(phone, phoneCode),
      category: 'subscription',
      country: useCountryCode(),
      region: useRegion(),
      [optIn ? 'optinDate' : 'optoutDate']: serverDate
    }

    // if a registered user - has a previous subscription - and is opting in,
    // we need to unsubscribe first
    if (shouldUnsubscribe(isGuest.value, smsNumber.value, optIn)) {
      await consumer.$updateSmsSubscription(getProfilePayload([{
        ...subscriptionPayload,
        optin: false,
        recipient: smsNumber.value,
        optoutDate: serverDate
      }]))
    }

    const subscriptionAction = isGuest.value
      ? consumer.$createSmsSubscription
      : consumer.$updateSmsSubscription
    await subscriptionAction(getProfilePayload([subscriptionPayload]))

    // for registered users, reload the profile
    await updateProfile(store, isGuest.value)
  }

  async function newsletter(data: NewsletterPayload) {
    const {
      optIn = true,
      loyaltyEnroll,
      loyaltyOptIn,
      firstName = '',
      lastName = '',
      email = '',
      birthDate = '',
      interests = '',
      gender,
      acquisitionType = '',
      campaignName = ''
    } = data
    const serverDate = utcIsoDate(await getServerDate())
    const region = useRegion()

    const payload: any = {
      consumerDetails: {
        firstName: firstName || undefined,
        lastName: lastName || undefined,
        email: email || undefined,
        birthDate: birthDate || undefined,
        gender: gender || undefined
      },
      enrollments: loyaltyEnroll ? [{ type: 'Loyalty', optin: true, doubleOptin: true, country: useCountryCode() }] : undefined,
      subscriptions: (newsletterSubscribed.value || optIn)
        ? [{
            type: 'Newsletter',
            channel: 'email',
            optin: optIn,
            doubleOptin: optIn,
            country: useCountryCode(),
            region,
            [!optIn ? 'optoutDate' : 'optinDate']: serverDate
          }]
        : []
    }

    if (optIn && interests) payload.preferences = { interests }

    // need to remove the interests if the user is opting out in the required locale
    if (!optIn && interestOnlyForSubscription?.requiredLocales.includes(useLocale()))
      payload.preferences = { interests: '' }

    if (loyaltyOptIn !== undefined) {
      payload.subscriptions.push({
        type: 'LoyaltyNewsletter',
        optin: loyaltyOptIn,
        doubleOptin: loyaltyOptIn,
        region,
        channel: 'email',
        [!loyaltyOptIn ? 'optoutDate' : 'optinDate']: serverDate
      })
    }
    const body = { consumerProfile: payload }
    if (acquisitionType)
      payload.consumerDetails.source = { acquisitionType, campaignName }

    if (email)
      consumerId.value = (await consumer.$createProfile(body)).consumerId || ''
    else
      await consumer.$updateProfile(body)

    // for registered users, reload the profile
    await updateProfile(store, isGuest.value)

    // return consumer id as it is necessary elsewhere, like GTM
    return consumerId.value
  }

  return {
    smsNumber,
    smsSubscription,
    sms,
    newsletterSubscribed,
    loyaltyNewsletterSubscribed,
    newsletter,
    consumerId
  }
}
