<template>
  <vf-link
    class="f-col items-center gap-2 text-center"
    :target="content.target?.targetAttribute"
    :to="content.target?.url"
    variant="quieter"
    @click="$emit('click', `${l1} - ${content.title}`)"
  >
    <base-picture
      v-if="content.mediaObject"
      :alt="content.mediaObject.alt || content.title"
      class="aspect-square"
      fit="cover"
      :height="imageSize"
      :src="getMediaUrl(content.mediaObject.url)"
      :title="content.mediaObject.seoTitle"
      :width="imageSize"
    />
    <span class="text-sm mt-2 ">{{ content.title }}</span>
  </vf-link>
</template>

<script lang="ts" setup>
import { MegaMenuL1ContextKey } from './context'
import type { Visual } from '#types/components/cms/mega-menu'

defineProps<{
  content: Visual
}>()

defineEmits<{
  click: [label: string]
}>()

const { imageSize } = useAppConfig().components.cms.megaMenuVisualSelector
const { getMediaUrl } = useCms()

const l1 = inject(MegaMenuL1ContextKey)
</script>
