<template>
  <base-link
    class="flex items-center gap-1 duration hover:bg-grey-90 "
    :class="[brandClasses.title, presets?.[content.menuStyle!], { [brandClasses.featured!]: l2?.featured }]"
    :target="content.target?.targetAttribute"
    :to="content.target?.url"
    @click.stop="$emit('click', `${l1} - ${l2?.title} - ${content.title}`)"
  >
    <vf-icon v-if="content.menuIcon" :name="content.menuIcon.icon" size="xs" />
    {{ content.title }}
  </base-link>
</template>

<script lang="ts" setup>
import { MegaMenuL1ContextKey, MegaMenuL2ContextKey } from './context'
import type { MenuItem } from '#types/components/cms/mega-menu'

defineProps<{
  content: MenuItem
}>()

defineEmits<{
  click: [label: string]
}>()

const { brandClasses, presets } = useAppConfig().components.cms.megaMenuL3Mobile

const l1 = inject(MegaMenuL1ContextKey)
const l2 = inject(MegaMenuL2ContextKey)
</script>
