<template>
  <base-popover
    v-slot="{ open, close, opened, toggle }"
    ref="popoverRef"
    class="h-full flex items-center"
    :class="brandClasses.popover"
    close-on-leave
    interactive
    strategy="fixed"
    @update:model-value="menuOpened = $event"
  >
    <div class="relative flex items-center">
      <component
        :is="triggerOnClick ? BaseButton : content.target ? BaseLink : 'div'"
        :id="contentId"
        :aria-expanded="opened"
        aria-haspopup="menu"
        class="group"
        :class="[brandClasses.link, presets?.[content.menuStyle!]?.menu, { active: opened }]"
        :style="{ 'outline-offset': '-2px' }"
        :target="content.target?.targetAttribute"
        :to="!triggerOnClick ? content.target?.url : undefined"
        @click="e => clickL1(e, toggle)"
        @keydown.esc="close"
        @mouseenter="e => (!triggerOnClick && hasSubItem) || menuHovered ? open(e) : ''"
      >
        <span class="relative flex items-center gap-x-1 ws-nowrap">
          <vf-icon
            v-if="content.menuIcon"
            class="pointer-events-none"
            height="26"
            :src="`/img/logos/${brand}/${content.menuIcon.icon}.svg`"
            width="26"
          />
          <span
            class="pointer-events-none"
            :class="[brandClasses.title, { 'sr-only': content.menuIcon && !content.titleVisibility }]"
            :style="[content.titleVisibility === 'title+icon' && { order: -1 }]"
          >
            {{ content.title }}
          </span>
          <span
            v-if="underlineOffset"
            class="absolute inset-x-0 bottom-0 scale-x-0 ease-out duration group-@active:scale-x-full group-hover:scale-x-full"
            :class="[
              brandClasses.underline,
              presets?.[content.menuStyle!]?.underline,
              { [brandClasses.underlineActive]: hasSubItem || suggestionsOpened },
            ]"
            :style="`margin-bottom: ${underlineOffset}; transform-origin: bottom left;`"
          />
        </span>
      </component>
      <base-button
        v-if="!triggerOnClick"
        :aria-expanded="opened"
        aria-haspopup="menu"
        :aria-label="replaceAll($t.megaMenuToggle, { category: content.title })"
        class="absolute inset-y-0 right-0 w-0 overflow-hidden focus:w-3"
        :style="{ 'outline-offset': '-2px' }"
        @click="toggle"
        @keydown.esc="close"
      >
        <vf-icon :dir="opened ? 'up' : 'down'" name="chevron" size="xs" />
      </base-button>
    </div>
    <lazy-base-popover-content
      class="relative right-0 z-above-header transition-opacity duration !left-0"
      :class="brandClasses.popoverContent"
      enter-active-class="transform ease-out"
      enter-from-class="op-0"
      leave-active-class="transform ease-in"
      leave-to-class="op-0"
      @keydown.esc="close"
    >
      <div v-if="!hideShadow" class="absolute-0 mt-3 bg-white" />
      <div
        :aria-labelledby="contentId"
        class="relative z-1 c-grey-10"
        :class="brandClasses.menu"
        data-test-id="vf-mega-menu"
        role="menu"
      >
        <div class="flex between">
          <vf-link
            v-if="!content.hideShopAll?.lg"
            class="flex items-center gap-2"
            :class="brandClasses.shopAll"
            :target="content.target?.targetAttribute"
            :to="content.target?.url"
            variant="quieter"
            @click="$emit('click', content.overrideShopAll || content.title)"
          >
            {{ content.overrideShopAll || `${$t.megaMenuShopAll} ${content.title}` }}
            <vf-icon :name="linkIcon" size="md" />
          </vf-link>
          <base-button :aria-label="$t.close" class="ml-a" @click="close">
            <vf-icon name="close" :size="closeIconSize" />
          </base-button>
        </div>
        <div v-if="content.columns?.length" class="flex gap-4" :class="brandClasses.columns">
          <div
            v-for="({ children }, i) in content.columns"
            :key="i"
            class="w-1/6"
            :class="brandClasses.column"
          >
            <cms-section
              v-for="menuItem in children"
              :key="menuItem.id"
              :section="{ name: menuItem.id, items: [{ ...menuItem, isFullWidth: true }] }"
            />
          </div>
          <div class="ml-a w-1/6">
            <cms-section
              v-if="content.menuBanner"
              :section="{ name: 'menuBanner', items: [{ ...content.menuBanner, isFullWidth: true }] }"
            />
          </div>
        </div>
        <div
          v-if="content.visualSelectorsTitle"
          class="relative mx-2 text-center"
          :class="brandClasses.visualSelectorsTitleWrapper"
        >
          <div class="absolute inset-x-0 inset-y-1/2 b-t b-grey-70" />
          <p class="relative inline bg-white px-6" :class="brandClasses.visualSelectorsTitle">
            {{ content.visualSelectorsTitle }}
          </p>
        </div>
        <ul
          v-if="!isString(content.visualSelectors) && content.visualSelectors?.length"
          class="flex justify-center px-2"
          :class="brandClasses.visualSelectors"
          data-test-id="vf-mega-menu-visuals"
        >
          <li v-for="visualSelector in content.visualSelectors" :key="visualSelector.id">
            <cms-section :section="{ name: visualSelector.id, items: [{ ...visualSelector, isFullWidth: true }] }" />
          </li>
        </ul>
      </div>
      <div v-if="!hideShadow" class="h-6 shadow-lg" tabindex="0" @focus="close" />
    </lazy-base-popover-content>
  </base-popover>
</template>

<script lang="ts" setup>
import { BaseButton, BaseLink } from '#components'
import type { BasePopover } from '#components'
import type { MenuItem } from '#types/components/cms/mega-menu'
import { HeaderContextKey } from '#commerce/components/vf/header/context'

const { content } = defineProps<{
  content: MenuItem
}>()

const emit = defineEmits<{
  click: [label: string]
}>()

const {
  megaMenu: { linkIcon, closeIconSize },
  megaMenuL1Desktop: { brandClasses, underlineOffset, hideShadow, triggerOnClick, presets }
} = useAppConfig().components.cms

const { brand } = useRuntimeConfig().public
const router = useRouter()
const popoverRef = ref<InstanceType<typeof BasePopover>>()
const contentId = `mega-menu-l1-${content.id}`

const { menuOpened, menuHovered, suggestionsOpened } = inject(HeaderContextKey)!

// simplify when bugfix https://digital.vfc.com/jira/browse/GLOBAL15-82870 will be done
const hasSubItem = content.columns?.some((column) => Object.keys(column).length)
  || content.menuBanner
  || content.visualSelectors?.length

const clickL1 = (e: Event, toggle: (e: Event) => void) => {
  emit('click', content.title)
  if (triggerOnClick) {
    menuHovered.value = true
    toggle(e)
  }
}

router.beforeEach(() => {
  if (popoverRef.value?.opened) popoverRef.value.close()
})
</script>
