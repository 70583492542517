/**
 * Extracts relative path from the url path.
 * @param path The URL path.
 * @returns The path without base url, origin domain
 * @category Utils
 */
export const getRelativeUrl = (path: string) => {
  try {
    const { origin } = new URL(path)
    return path.replace(origin, '')
  }
  catch (err: any) {
    log.error(`[@domains/commerce/utils] Cannot get relative path from given url: ${path}`, err)
    return path
  }
}
