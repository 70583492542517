<template>
  <base-interpolator :content>
    <template v-for="(to, key) in links" #[key]="{ args: [message] }" :key="key">
      <vf-link :class="linkClasses?.[key]" :data-test-id="dataTestIds?.[key]" target="_blank" :to>
        {{ message }}
      </vf-link>
    </template>
    <template #bold="{ args: [message] }">
      <span class="fw-bold">{{ message }}</span>
    </template>
    <template #brand="{ args: [message] }">
      {{ message || $t.brand }}
    </template>
    <template #uppercase="{ args: [message] }">
      <span class="uppercase">{{ message }}</span>
    </template>
  </base-interpolator>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'

defineProps<{
  content: string
  dataTestIds?: Partial<{ [K in keyof typeof links]: string }>
  linkClasses?: Partial<{ [K in keyof typeof links]: CSSClass }>
}>()

const links = {
  'brand-terms': '/brand-terms',
  'loyalty-terms': '/loyalty-terms',
  'sale-terms': '/sale-terms',
  'sms-terms': '/sms-terms',
  'associate-discount-terms': '/associate-discount-terms',
  'privacy': '/privacy',
  'returns': '/help/returns/how-to-return',
}
</script>
