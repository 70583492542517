import type { RouteLocationNormalizedLoaded } from 'vue-router'
import type { ContentPagePageItems } from '#root/api/clients/content/data-contracts'

interface GenericEspotSection {
  name: string
  items?: ContentPagePageItems
  lazy?: boolean
}

export const mergeInheritedEspotSections = (response: any, route: RouteLocationNormalizedLoaded, inheritedFirstEspotsOnRoutes: { pattern: string, espots: string[] }[]) => {
  // Since this gets transformed on SSR, we ignore if the transformation if the sectionMap is already on the response, preventing transforming twice
  if (response.value?.content.sections && !response.value?.content.sectionsMap) {
    const mergedSections = response.value?.content.sections.reduce((acc, { name: originalName, items = [] }) => {
      const inheritedFirstEspots = inheritedFirstEspotsOnRoutes
        .filter(({ pattern }) => convertPatternToRegex(pattern).test(route.path)).map(({ espots }) => espots).flat()

      const name = originalName.replace('-inherited', '')

      if (items.length === 0)
        return { ...acc, [name]: acc[name] || null }

      const existingItems = acc[name]?.items || []

      return {
        ...acc,
        [name]: {
          name,
          lazy: name.includes('lazy'),
          items: inheritedFirstEspots.includes(name) && originalName.includes('-inherited')
            ? [...items, ...existingItems]
            : [...existingItems, ...items],
        },
      }
    }, {} as Record<string, GenericEspotSection>)

    response.value.content.sectionsMap = mergedSections
    response.value.content.sections = Object.values(mergedSections)
  }
}
